import React from "react";
import {
    BrowserRouter as Router,
    Route,
    Routes
} from 'react-router-dom';
import PoolTeamAdmin from "./components/PoolTeamAdmin";
import PoolTeamDetails from "./components/PoolTeamDetails";
import PoolTeamsTable from "./components/PoolTeamsTable";
import PlayerDetails from "./components/PlayerDetails";
import FreeAgents from "./components/FreeAgents";
import LNHAAppBar from "./components/LNHAAppBar";

function MainWindow() {
    return (
        <Router>
            <LNHAAppBar />
            <Routes>
                <Route path="/" element={<PoolTeamsTable />} />
                <Route path="/equipe/:id" element={<PoolTeamDetails />} />
                <Route path="/admin" element={<PoolTeamAdmin />} />
                <Route path="/joueur/:id" element={<PlayerDetails />} />
                <Route path="/agentslibres" element={<FreeAgents />} />
            </Routes>
        </Router>
    );
}

export default MainWindow;