import axios from 'axios';
import { BASE_URL as APP_URL } from '../constants';

//const API_URL = 'http://192.168.1.229:5044/api/PoolTeams';  // Adjust this URL to your API endpoint
const API_URL = `${APP_URL}/api/PoolTeams`;  // Update the port if different

export const fetchPoolTeams = () => {
    return axios.get(API_URL).then(response => response.data);
};

export const getTeamById = (id) => {
    return axios.get(`${API_URL}/${id}`);
};

export const addPlayerToPoolTeam = ({ teamId, playerId }) => {

    const endpoint = `${API_URL}/players`;
    return axios.post(endpoint, {
        teamId: teamId,
        playerId: playerId
    }).then(response => response.data);
};
