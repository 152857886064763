import React, { useState } from 'react';
import { useMediaQuery, Table, Button, TableBody, TableCell, TableSortLabel, TableHead, TableRow, Paper, Container, Box, CircularProgress, Stack, Typography, Hidden, TableFooter } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getTeamById } from '../services/PoolTeamService';
import { useNavigate } from 'react-router-dom';
import { ArrowBackIosNewRounded } from '@mui/icons-material';
import { BASE_URL as APP_URL } from '../constants';
import { shortenName } from '../Utilities';


export default function PoolTeamDetails() {
    const params = useParams();
    const teamId = params.id;

    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const isMediumScreen = useMediaQuery(theme => theme.breakpoints.down('lg'));
    const navigate = useNavigate();
    const [data, setData] = useState(null);



    const filterPlayers = (poolteam, filter) => {
        return poolteam.data.poolTeamPlayers.filter((poolTeamPlayer) => poolTeamPlayer.isInStartingLineup === filter)
            .map(poolTeamPlayer => {
                const player = poolTeamPlayer.player;
                const gamesPlayed = player.position === "G" ? player.goalieStat?.gamesPlayed ?? 0 : player.skaterStat?.gamesPlayed ?? 0;
                const points = player.position === "G" ? player.goalieStat?.points ?? 0 : player.skaterStat?.points ?? 0;
                const ppg = gamesPlayed > 0 ? points / gamesPlayed : 0;
                return {
                    rank: poolTeamPlayer.rank,
                    shortName: shortenName(player.name),
                    ...player,
                    position: player?.playerLnhaplayerMatched?.lnhaplayer?.position ?
                        `${player?.playerLnhaplayerMatched?.lnhaplayer?.position}${player?.playerLnhaplayerMatched?.lnhaplayer?.lineNumber ?? ""}` :
                        player?.playerForecasterPlayerMatched?.forecasterPlayer?.position ?? player.position,
                    points: points,
                    gamesPlayed: gamesPlayed,
                    teamAbr: player.team?.abbreviation ?? '---',
                    salary: player?.playerLnhaplayerMatched?.lnhaplayer?.salary ?? 0.50,
                    ppg: ppg,
                };
            })
    };

    const { isLoading, isError } = useQuery('poolteam_' + teamId, () => getTeamById(teamId), {
        onSuccess: (poolTeam) => {
            const stats = poolTeam.data.poolTeamPlayers.reduce((acc, poolTeamPlayer) => {
                const player = poolTeamPlayer.player;
                if (poolTeamPlayer.isInStartingLineup) {
                    if (player.skaterStat) {
                        acc.lineup.goals += player.skaterStat.goals;
                        acc.lineup.assists += player.skaterStat.assists;
                        acc.lineup.points += player.skaterStat.points;
                        acc.lineup.gamesPlayed += player.skaterStat.gamesPlayed;
                    }
                    if (player.goalieStat) {
                        acc.lineup.goals += player.goalieStat.goals;
                        acc.lineup.assists += player.goalieStat.assists;
                        acc.lineup.points += player.goalieStat.points;
                        acc.lineup.gamesPlayed += player.goalieStat.gamesPlayed;
                        acc.lineup.wins += player.goalieStat.wins;
                        acc.lineup.shutouts += player.goalieStat.shutouts;
                        acc.lineup.otl += player.goalieStat.overtimeLosses;
                    }
                    acc.lineup.salary += player?.playerLnhaplayerMatched?.lnhaplayer?.salary ?? 0.50;
                } else {
                    if (player.skaterStat) {
                        acc.farm.goals += player.skaterStat.goals;
                        acc.farm.assists += player.skaterStat.assists;
                        acc.farm.points += player.skaterStat.points;
                        acc.farm.gamesPlayed += player.skaterStat.gamesPlayed;
                    }
                    if (player.goalieStat) {
                        acc.farm.goals += player.goalieStat.goals;
                        acc.farm.assists += player.goalieStat.assists;
                        acc.farm.points += player.goalieStat.points;
                        acc.farm.gamesPlayed += player.goalieStat.gamesPlayed;
                        acc.farm.wins += player.goalieStat.wins;
                        acc.farm.shutouts += player.goalieStat.shutouts;
                        acc.farm.otl += player.goalieStat.overtimeLosses;
                    }
                    acc.farm.salary += player?.playerLnhaplayerMatched?.lnhaplayer?.salary ?? 0.50;
                }

                return acc;
            }, {
                lineup: { goals: 0, assists: 0, points: 0, gamesPlayed: 0, wins: 0, shutouts: 0, otl: 0, salary: 0 },
                farm: { goals: 0, assists: 0, points: 0, gamesPlayed: 0, wins: 0, shutouts: 0, otl: 0, salary: 0 }
            });

            setData({
                teamId: poolTeam.data.id,
                teamName: poolTeam.data.team.frName,
                logoUrl: poolTeam.data.team.logoUrl,
                stats: stats,
                // goals: stats.goals,
                // assists: stats.assists,
                // points: stats.points,
                // salary: stats.salary,
                ppgLineup: stats.lineup.gamesPlayed > 0 ? stats.lineup.points / stats.lineup.gamesPlayed : 0,
                ppgFarm: stats.farm.gamesPlayed > 0 ? stats.farm.points / stats.farm.gamesPlayed : 0,
                // gamesPlayed: stats.gamesPlayed,
                lineupPlayers: filterPlayers(poolTeam, true),
                farmPlayers: filterPlayers(poolTeam, false),
                team: poolTeam.data.team,
            });
        }
    });

    const [sortConfigLineup, setSortConfigLineup] = useState({ key: 'rank', direction: 'asc' });
    const [sortConfigFarm, setSortConfigFarm] = useState({ key: 'points', direction: 'desc' });

    const requestSortLineup = (key) => {
        let direction = 'asc';
        if (sortConfigLineup.key === key && sortConfigLineup.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfigLineup({ key, direction });
    };

    const requestSortFarm = (key) => {
        let direction = 'asc';
        if (sortConfigFarm.key === key && sortConfigFarm.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfigFarm({ key, direction });
    };

    const sortedDataLineup = [...(data?.lineupPlayers ?? [])].sort((a, b) => {
        if (a[sortConfigLineup.key] < b[sortConfigLineup.key]) {
            return sortConfigLineup.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfigLineup.key] > b[sortConfigLineup.key]) {
            return sortConfigLineup.direction === 'asc' ? 1 : -1;
        }
        return 0;
    });

    const sortedDataFarm = [...(data?.farmPlayers ?? [])].sort((a, b) => {
        if (a[sortConfigFarm.key] < b[sortConfigFarm.key]) {
            return sortConfigFarm.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfigFarm.key] > b[sortConfigFarm.key]) {
            return sortConfigFarm.direction === 'asc' ? 1 : -1;
        }
        return 0;
    });

    return (
        <Container>
            <Box m={2}>
                <Box marginTop={2} marginBottom={1}>
                    <Button
                        startIcon={<ArrowBackIosNewRounded />}
                        variant="text"
                        color="primary"
                        onClick={() => navigate('/')}
                    >
                        Retour au classement
                    </Button>
                </Box>
                <Paper>
                    {isLoading || !data ? (
                        <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                            <CircularProgress variant='indeterminate' size={100} />
                        </Box>
                    ) : (
                        <>
                            <Box p={2}>
                                <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        <Box sx={{ width: 100, height: 100, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <img src={`${APP_URL}${data.team?.logoUrl}`} alt={data.team?.frName} style={{ maxWidth: '100%', maxHeight: '100%' }} />
                                        </Box>
                                        <Typography variant="h5">{data.team?.frName}</Typography>
                                    </Stack>
                                </Stack>
                            </Box>
                            <div style={{ overflowX: 'auto' }}>
                                <Typography variant="h6" style={{ padding: '16px' }}>
                                    Alignement
                                </Typography>
                                <Table size='small'>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align='right' style={{ width: 20, paddingRight: 0, paddingLeft: 4 }}>
                                                <TableSortLabel
                                                    active={sortConfigLineup.key === 'rank'}
                                                    direction={sortConfigLineup.direction}
                                                    onClick={() => requestSortLineup('rank')}
                                                >
                                                    Rang
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell>
                                                <TableSortLabel
                                                    active={sortConfigLineup.key === 'name'}
                                                    direction={sortConfigLineup.direction}
                                                    onClick={() => requestSortLineup('name')}
                                                >
                                                    Nom
                                                </TableSortLabel>
                                            </TableCell>
                                            <Hidden lgDown>
                                                <TableCell>
                                                    <TableSortLabel
                                                        active={sortConfigLineup.key === 'position'}
                                                        direction={sortConfigLineup.direction}
                                                        onClick={() => requestSortLineup('position')}
                                                    >
                                                        Position
                                                    </TableSortLabel>
                                                </TableCell>
                                            </Hidden>
                                            <TableCell align="right">
                                                <TableSortLabel
                                                    active={sortConfigLineup.key === 'points'}
                                                    direction={sortConfigLineup.direction}
                                                    onClick={() => requestSortLineup('points')}
                                                >
                                                    Points
                                                </TableSortLabel>
                                            </TableCell>
                                            <Hidden smDown>
                                                <TableCell align="right">
                                                    <TableSortLabel
                                                        active={sortConfigLineup.key === 'gamesPlayed'}
                                                        direction={sortConfigLineup.direction}
                                                        onClick={() => requestSortLineup('gamesPlayed')}
                                                    >
                                                        PJ
                                                    </TableSortLabel>
                                                </TableCell>
                                            </Hidden>
                                            <Hidden smDown>
                                                <TableCell align="right">
                                                    <TableSortLabel
                                                        active={sortConfigLineup.key === 'ppg'}
                                                        direction={sortConfigLineup.direction}
                                                        onClick={() => requestSortLineup('ppg')}
                                                    >
                                                        P/P
                                                    </TableSortLabel>
                                                </TableCell>
                                            </Hidden>
                                            <Hidden smDown>
                                                <TableCell align="right">
                                                    <TableSortLabel
                                                        active={sortConfigLineup.key === 'salary'}
                                                        direction={sortConfigLineup.direction}
                                                        onClick={() => requestSortLineup('salary')}
                                                    >
                                                        Salaire
                                                    </TableSortLabel>
                                                </TableCell>
                                            </Hidden>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {sortedDataLineup.map(player => (
                                            <TableRow key={player.id}>
                                                <TableCell align='right' style={{ width: 20, paddingRight: 0, paddingLeft: 4 }}>{player.rank}</TableCell>
                                                <TableCell>
                                                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                                                        {player.team && <img src={`${APP_URL}${player.team?.logoUrl}`} alt={data.team?.frName} width={25} height={25} />}
                                                        {!player.team && <div style={{ width: 25, height: 25 }} />}
                                                        <div>{isMediumScreen ? `${player.shortName} (${player.position})` : player.name}</div>
                                                    </Stack>
                                                </TableCell>
                                                <Hidden lgDown>
                                                    <TableCell>{player.position}</TableCell>
                                                </Hidden>
                                                <TableCell align="right">{player.points}</TableCell>
                                                <Hidden smDown>
                                                    <TableCell align="right">{player.gamesPlayed}</TableCell>
                                                </Hidden>
                                                <Hidden smDown>
                                                    <TableCell align="right">{player.ppg.toFixed(2)}</TableCell>
                                                </Hidden>
                                                <Hidden smDown>
                                                    <TableCell align="right">{player.salary.toFixed(2)}</TableCell>
                                                </Hidden>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TableCell align="right" />
                                            <TableCell>
                                                <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                                                    <div style={{ width: 25, height: 25 }} />
                                                    <div>Totaux</div>
                                                </Stack></TableCell>
                                            <Hidden lgDown>
                                                <TableCell />
                                            </Hidden>
                                            <TableCell align="right">{data.stats.lineup.points}</TableCell>
                                            <Hidden smDown>
                                                <TableCell align="right">{data.stats.lineup.gamesPlayed}</TableCell>
                                            </Hidden>
                                            <Hidden smDown>
                                                <TableCell align="right">{data.ppgLineup.toFixed(2)}</TableCell>
                                            </Hidden>
                                            <Hidden smDown>
                                                <TableCell align="right">{data.stats.lineup.salary.toFixed(2)}</TableCell>
                                            </Hidden>
                                        </TableRow>
                                    </TableFooter>
                                </Table>

                                <Box mt={3}>
                                    <Typography variant="h6" style={{ padding: '16px' }}>
                                        Club école
                                    </Typography>
                                </Box>
                                <Table size='small'>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <TableSortLabel
                                                    active={sortConfigFarm.key === 'name'}
                                                    direction={sortConfigFarm.direction}
                                                    onClick={() => requestSortFarm('name')}
                                                >
                                                    Nom
                                                </TableSortLabel>
                                            </TableCell>
                                            <Hidden lgDown>
                                                <TableCell>
                                                    <TableSortLabel
                                                        active={sortConfigFarm.key === 'position'}
                                                        direction={sortConfigFarm.direction}
                                                        onClick={() => requestSortFarm('position')}
                                                    >
                                                        Position
                                                    </TableSortLabel>
                                                </TableCell>
                                            </Hidden>
                                            <TableCell align="right">
                                                <TableSortLabel
                                                    active={sortConfigFarm.key === 'points'}
                                                    direction={sortConfigFarm.direction}
                                                    onClick={() => requestSortFarm('points')}
                                                >
                                                    Points
                                                </TableSortLabel>
                                            </TableCell>
                                            <Hidden smDown>
                                                <TableCell align="right">
                                                    <TableSortLabel
                                                        active={sortConfigFarm.key === 'gamesPlayed'}
                                                        direction={sortConfigFarm.direction}
                                                        onClick={() => requestSortFarm('gamesPlayed')}
                                                    >
                                                        PJ
                                                    </TableSortLabel>
                                                </TableCell>
                                            </Hidden>
                                            <Hidden smDown>
                                                <TableCell align="right">
                                                    <TableSortLabel
                                                        active={sortConfigFarm.key === 'ppg'}
                                                        direction={sortConfigFarm.direction}
                                                        onClick={() => requestSortFarm('ppg')}
                                                    >
                                                        P/P
                                                    </TableSortLabel>
                                                </TableCell>
                                            </Hidden>
                                            <Hidden smDown>
                                                <TableCell align="right">
                                                    <TableSortLabel
                                                        active={sortConfigFarm.key === 'salary'}
                                                        direction={sortConfigFarm.direction}
                                                        onClick={() => requestSortFarm('salary')}
                                                    >
                                                        Salaire
                                                    </TableSortLabel>
                                                </TableCell>
                                            </Hidden>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {sortedDataFarm.map(player => (
                                            <TableRow key={player.id}>
                                                <TableCell>
                                                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                                                        {player.team && <img src={`${APP_URL}${player.team?.logoUrl}`} alt={data.team?.frName} width={25} height={25} />}
                                                        {!player.team && <div style={{ width: 25, height: 25 }} />}
                                                        <div>{isMediumScreen ? `${player.shortName} (${player.position})` : player.name}</div>
                                                    </Stack>
                                                </TableCell>
                                                <Hidden lgDown>
                                                    <TableCell>{player.position}</TableCell>
                                                </Hidden>
                                                <TableCell align="right">{player.points}</TableCell>
                                                <Hidden smDown>
                                                    <TableCell align="right">{player.gamesPlayed}</TableCell>
                                                </Hidden>
                                                <Hidden smDown>
                                                    <TableCell align="right">{player.ppg.toFixed(2)}</TableCell>
                                                </Hidden>
                                                <Hidden smDown>
                                                    <TableCell align="right">{player.salary.toFixed(2)}</TableCell>
                                                </Hidden>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TableCell>
                                                <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                                                    <div style={{ width: 25, height: 25 }} />
                                                    <div>Totaux</div>
                                                </Stack>
                                            </TableCell>
                                            <Hidden lgDown>
                                                <TableCell />
                                            </Hidden>
                                            <TableCell align="right">{data.stats.farm.points}</TableCell>
                                            <Hidden smDown>
                                                <TableCell align="right">{data.stats.farm.gamesPlayed}</TableCell>
                                            </Hidden>
                                            <Hidden smDown>
                                                <TableCell align="right">{data.ppgFarm.toFixed(2)}</TableCell>
                                            </Hidden>
                                            <Hidden smDown>
                                                <TableCell align="right">{data.stats.farm.salary.toFixed(2)}</TableCell>
                                            </Hidden>
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </div>
                        </>
                    )}
                </Paper>
            </Box>
        </Container>
    );
}

