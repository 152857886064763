import React from 'react';
import { IconButton, Tooltip, TablePagination, Stack } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

function TablePaginationActions(props) {
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    return (
        <div>
            <Stack direction="row">
                <IconButton onClick={handleBackButtonClick} disabled={page === 0}>
                    <KeyboardArrowLeft />
                </IconButton>

                <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1}>
                    <KeyboardArrowRight />
                </IconButton>
            </Stack>

        </div>
    );
}

export default TablePaginationActions;