import React, { useState, useEffect } from 'react';
import { Select, MenuItem, FormControl, ListItemIcon, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import { fetchPoolTeams } from '../services/PoolTeamService';  // Update the path accordingly
import { BASE_URL as APP_URL } from '../constants';

export default function PoolTeamsDropdown({ onTeamChange }) {
    //const [data, setData] = useState([]);
    const { data: poolTeams, isLoading, isError } = useQuery('poolTeams_admin', fetchPoolTeams);

    const [selectedTeam, setSelectedTeam] = useState('');

    // useEffect(() => {
    //     if (selectedTeam)
    //         setSelectedTeam(poolTeams.find(team => team.id == selectedTeam.id));
    // }, [poolTeams, setSelectedTeam]);

    if (isLoading) {
        return <span>Loading...</span>;  // You can also return a skeleton or spinner here
    }

    if (isError) {
        return <span>Error fetching pool teams</span>;
    }

    return (
        <FormControl fullWidth variant="outlined">
            <Select
                value={selectedTeam}
                onChange={(e) => {
                    setSelectedTeam(e.target.value);
                    const chosenTeam = poolTeams.find(team => team.team.id === e.target.value);
                    if (onTeamChange) onTeamChange(chosenTeam);
                }}
                displayEmpty
            >
                {poolTeams.map(poolTeam =>
                (
                    <MenuItem key={poolTeam.team.id} value={poolTeam.team.id}>
                        <ListItemIcon>
                            <img src={`${APP_URL}${poolTeam.team.logoUrl}`} alt={poolTeam.team.frName} width="40" height="40" />
                        </ListItemIcon>
                        <Typography variant="inherit">{poolTeam.team.frName}</Typography>
                    </MenuItem>
                )
                )}
            </Select>
        </FormControl>
    );
}
