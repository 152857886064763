import React from 'react';
import {
    Avatar, Typography, Divider, Grid, Table, TableBody, TableCell,
    TableHead, TableRow, Box, Paper
} from '@mui/material';
import PlayerAvatar from './PlayerAvatar';
import { useParams } from 'react-router-dom';
import { playerService } from '../services/PlayerService';
import { useQuery } from 'react-query';


function PlayerDetails() {
    const params = useParams();
    const playerId = params.id;

    const playerStats = [];
    const recentGames = [];

    const { data: player, isLoading, isError } = useQuery(['playerDetails', playerId], () => playerService.getPlayerById(playerId));

    if (isLoading) return <div>Loading...</div>;
    if (isError) return <div>Error fetching player details</div>;

    return (
        <Box mt={2} p={2}>
            <Grid container spacing={2}>

                {/* Player Avatar */}
                <Grid item xs={12} md={4} lg={3}>
                    <Box display="flex" justifyContent="center" md={{ justifyContent: "flex-start" }}>
                        <PlayerAvatar playerId={playerId} /> {/* Using PlayerAvatar here */}
                    </Box>
                </Grid>

                {/* Player Name, Team, Number, Position */}
                <Grid item xs={12} md={8} lg={3}>
                    <Box display="flex" flexDirection="column" alignItems="center" md={{ alignItems: "flex-start" }}>
                        <Typography variant="h5">{player.data.name}</Typography>
                        <Box display="flex" alignItems="center" mt={1}>
                            <Avatar src={player.data.team?.logoUrl} variant="square" size="small" />
                            <Divider orientation="vertical" flexItem />
                            <Typography variant="body1">{player.data.number}</Typography>
                            <Divider orientation="vertical" flexItem />
                            <Typography variant="body1">{player.data.position}</Typography>
                        </Box>
                    </Box>
                </Grid>

                {/* Player Properties */}
                <Grid item xs={6} md={6} lg={3}>
                    <Box display="flex" flexDirection="column">
                        <Typography variant="body2">Height: {player.height}</Typography>
                        <Typography variant="body2">Weight: {player.weight}</Typography>
                        <Typography variant="body2">Birthplace: {player.birthplace}</Typography>
                    </Box>
                </Grid>

                <Grid item xs={6} md={6} lg={3}>
                    <Box display="flex" flexDirection="column">
                        <Typography variant="body2">Salary: {player.salary}</Typography>
                        <Typography variant="body2">Forecast Position: {player.forecastPosition}</Typography>
                    </Box>
                </Grid>

                {/* Season Stats */}
                <Grid item xs={12}>
                    <Typography align="center" variant="h6">2023-24 Season</Typography>
                    <Paper elevation={1} variant="outlined">
                        <Grid container spacing={2}>
                            {['GP', 'G', 'A', 'P'].map((statKey, index) => (
                                <Grid item xs={3} key={statKey}>
                                    <Typography align="center" variant="caption">{statKey}</Typography>
                                    <Typography align="center" variant="h6">{playerStats[statKey]}</Typography>
                                </Grid>
                            ))}
                        </Grid>
                    </Paper>
                </Grid>

                {/* Recent Games Table */}
                <Grid item xs={12}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>Opp</TableCell>
                                <TableCell>G</TableCell>
                                <TableCell>A</TableCell>
                                <TableCell>P</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {recentGames.map(game => (
                                <TableRow key={game.date}>
                                    <TableCell>{game.date}</TableCell>
                                    <TableCell>{game.opponent}</TableCell>
                                    <TableCell>{game.goals}</TableCell>
                                    <TableCell>{game.assists}</TableCell>
                                    <TableCell>{game.points}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Grid>

            </Grid>
        </Box>
    );
}

export default PlayerDetails;
