import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { playerService } from '../services/PlayerService';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import _ from 'lodash';
import useDebounce from './useDebounce';

function PlayerSearch({ onPlayerChange }) {
    const [searchText, setSearchText] = useState("");
    const debouncedSeaechText = useDebounce(searchText, 300);
    const [optionsData, setOptionsData] = useState([]);
    const { data: players = [] } = useQuery('searchPlayersByName_' + debouncedSeaechText,
        () => {
            if (debouncedSeaechText?.length >= 3)
                return playerService.searchPlayersByName(debouncedSeaechText);
        }
    );

    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearchText(value);

        if (value.length < 3 && optionsData) {
            setOptionsData(null);
        }
    };

    useEffect(() => {
        setOptionsData(players.data);
    }, [players.data]);

    return (
        <Autocomplete
            options={optionsData ?? []}
            getOptionLabel={(option) => option.name}
            style={{ width: 300 }}
            renderOption={(props, option) => (
                <li {...props} key={option.id}>
                    {option.name} ({option.position}) ({option.isActive.toString()} {option.teamId})
                </li>
            )}
            onChange={(event, newValue) => {
                onPlayerChange(newValue);
            }}
            renderInput={(params) =>
                <TextField
                    {...params}
                    label="Search Players"
                    variant="outlined"
                    onChange={handleSearchChange}
                />}
        />
    );
}

export default PlayerSearch;
