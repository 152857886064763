import axios from 'axios';
import { BASE_URL as APP_URL } from '../constants';

//const BASE_URL = "http://192.168.1.229:5044/api";  // Update the port if different
const BASE_URL = `${APP_URL}/api`;  // Update the port if different

export const playerService = {
  getAllPlayers: () => axios.get(`${BASE_URL}/players`),
  
  getPlayerById: (id) => axios.get(`${BASE_URL}/players/${id}`),
  
  searchPlayersByName: (name) => axios.get(`${BASE_URL}/players/search`, {
    params: {
      name: name
    }
  }),
  
  getActivePlayers: () => axios.get(`${BASE_URL}/players/active`),
  
  getInactivePlayers: () => axios.get(`${BASE_URL}/players/inactive`),

  getFreeAgents: ()=> axios.get(`${BASE_URL}/players/freeagents`),
};
