import React, { useState } from 'react';
import PlayerSearch from './PlayerSearch';
import PoolTeamsDropdown from './PoolTeansDropdown';
import { Container } from '@mui/material';
import PoolTeamTable from './PoolTeamDetails';
import { useMutation, useQueryClient } from 'react-query';
import { ToastContainer, toast } from 'react-toastify'; // Importing react-toastify for toasts
import 'react-toastify/dist/ReactToastify.css'; // Don't forget to import the CSS!
import { addPlayerToPoolTeam } from '../services/PoolTeamService';


function PoolTeamAdmin() {
    const [selectedPoolTeam, setSelectedPoolTeam] = useState(null);
    const [selectedPlayer, setSelectedPlayer] = useState(null);
    const queryClient = useQueryClient();

    // Define the mutation using react-query
    const mutation = useMutation(addPlayerToPoolTeam, {
        onSuccess: () => {
            // Invalidate and refetch something when the mutation is successful
            queryClient.invalidateQueries('poolTeams_admin');

            // Display success toast
            toast.success("Player added successfully!");
        },
        onError: (error, variables, context) => {
            console.error("An error occurred:", error);
            // Display error toast
            toast.error("Error adding player. Please try again.");
        }
    });

    const handleTeamChange = (team) => {
        setSelectedPoolTeam(team);
    };

    const handlePlayerChange = (player) => {
        setSelectedPlayer(player);
    };

    const handleAddPlayerToTeam = () => {
        if (selectedPoolTeam && selectedPlayer) {
            mutation.mutate({
                teamId: selectedPoolTeam.id,
                playerId: selectedPlayer.id
            });
        }
    };

    return (
        <Container>
            <div>
                <PoolTeamsDropdown onTeamChange={handleTeamChange} />
                <PlayerSearch onPlayerChange={handlePlayerChange} />
                <button
                    onClick={handleAddPlayerToTeam}
                    disabled={!selectedPoolTeam || !selectedPlayer}
                >
                    Add Player to Team
                </button>
                <PoolTeamTable poolTeam={selectedPoolTeam} />
                <ToastContainer />
            </div>
        </Container>
    );
}

export default PoolTeamAdmin;
