import React, { useState } from 'react';
import { useMediaQuery, Paper, Table, TableBody, TableCell, TableHead, TableRow, TablePagination, TextField, Box, Container, Typography, CircularProgress, TableSortLabel, Stack, Hidden, InputAdornment, IconButton } from '@mui/material';
import { useQuery } from 'react-query';
import { playerService } from '../services/PlayerService';
import { BASE_URL as APP_URL } from '../constants';
import { shortenName } from '../Utilities';
import ClearIcon from '@mui/icons-material/Clear';
import TablePaginationActions from './TablePaginationActions';

const FreeAgents = () => {
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const isMediumScreen = useMediaQuery(theme => theme.breakpoints.down('lg'));

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(isSmallScreen ? 10 : 20);
    const [filter, setFilter] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: 'points', direction: 'desc' });
    const [data, setData] = useState([]);

    const { isLoading, isError } = useQuery('freeagents', () => playerService.getFreeAgents(), {
        onSuccess: (players) => {
            const data = players.data.map((player) => {
                const gamesPlayed = player?.position === "G" ? player.goalieStat?.gamesPlayed ?? 0 : player.skaterStat?.gamesPlayed ?? 0;
                const points = player?.position === "G" ? player.goalieStat?.points ?? 0 : player.skaterStat?.points ?? 0;
                const ppg = gamesPlayed > 0 ? points / gamesPlayed : 0;
                return {
                    shortName: shortenName(player.name),
                    ...player,
                    position: player?.playerLnhaplayerMatched?.lnhaplayer && player?.playerForecasterPlayerMatched?.forecasterPlayer ?
                        `${player?.playerForecasterPlayerMatched?.forecasterPlayer?.position}${player?.playerLnhaplayerMatched?.lnhaplayer?.lineNumber ?? ""}` :
                        player?.playerForecasterPlayerMatched?.forecasterPlayer?.position ?? player.position,
                    points: points,
                    gamesPlayed: gamesPlayed,
                    teamAbr: player.team?.abbreviation ?? '---',
                    salary: player?.playerLnhaplayerMatched?.lnhaplayer?.salary ?? 0.50,
                    ppg: ppg,
                };
            });

            setData(data);
        }
    });

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
        setPage(0);  // Reset page to 1 (0-indexed) when filter changes
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleSort = (columnName) => {
        const isAsc = sortConfig.key === columnName && sortConfig.direction === "asc";
        setSortConfig({ key: columnName, direction: isAsc ? 'desc' : 'asc' });
    };

    const filteredData = data.filter(player =>
        player.name.toLowerCase().includes(filter.toLowerCase())
    );

    const sortedData = filteredData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? 1 : -1;
        }
        // Secondary sort by 'points' when primary keys are equal
        return a.points < b.points ? 1 : -1;
        // return 0;
    });

    const clearFilter = () => {
        setFilter('');
        setPage(0);  // Reset page to 1 (0-indexed) when filter is cleared
    };

    return (
        <Container>
            <Box m={2}>
                <Paper>
                    {isLoading || !data ? (
                        <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                            <CircularProgress variant='indeterminate' size={100} />
                        </Box>
                    ) : (<>
                        <Typography variant="h6" style={{ padding: '16px' }}>
                            Agents libres
                        </Typography>
                        <Box m={2}>
                            <TextField
                                label="Filtrer par nom"
                                value={filter}
                                onChange={handleFilterChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {filter ? (
                                                <IconButton
                                                    aria-label="enlever le filtre"
                                                    onClick={clearFilter}
                                                    edge="end"
                                                    size="small"
                                                >
                                                    <ClearIcon />
                                                </IconButton>
                                            ) : (
                                                // Placeholder with dimensions of the clear icon
                                                <div style={{ width: 31, height: 31 }}></div>
                                            )}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <TableSortLabel active={sortConfig.key === 'name'} direction={sortConfig.direction} onClick={() => handleSort('name')}>
                                            Name
                                        </TableSortLabel>
                                    </TableCell>
                                    <Hidden lgDown>
                                        <TableCell>
                                            <TableSortLabel active={sortConfig.key === 'position'} direction={sortConfig.direction} onClick={() => handleSort('position')}>
                                                Position
                                            </TableSortLabel>
                                        </TableCell>
                                    </Hidden>
                                    <TableCell align="right">
                                        <TableSortLabel active={sortConfig.key === 'points'} direction={sortConfig.direction} onClick={() => handleSort('points')}>
                                            Points
                                        </TableSortLabel>
                                    </TableCell>
                                    <Hidden smDown>
                                        <TableCell align="right">
                                            <TableSortLabel active={sortConfig.key === 'gamesPlayed'} direction={sortConfig.direction} onClick={() => handleSort('gamesPlayed')}>
                                                PJ
                                            </TableSortLabel>
                                        </TableCell>
                                    </Hidden>
                                    <Hidden smDown>
                                        <TableCell align="right">
                                            <TableSortLabel active={sortConfig.key === 'ppg'} direction={sortConfig.direction} onClick={() => handleSort('ppg')}>
                                                P/P
                                            </TableSortLabel>
                                        </TableCell>
                                    </Hidden>
                                    <Hidden smDown>
                                        <TableCell align="right">
                                            <TableSortLabel active={sortConfig.key === 'salary'} direction={sortConfig.direction} onClick={() => handleSort('salary')}>
                                                Salaire
                                            </TableSortLabel>
                                        </TableCell>
                                    </Hidden>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(player => (
                                    <TableRow key={player.id}>
                                        <TableCell>
                                            <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                                                {player.team && <img src={`${APP_URL}${player.team?.logoUrl}`} alt={player.team?.frName} width={25} height={25} />}
                                                {!player.team && <div style={{ width: 25, height: 25 }} />}
                                                <div>{isMediumScreen ? `${player.shortName} (${player.position})` : player.name}</div>
                                            </Stack>
                                        </TableCell>
                                        <Hidden lgDown>
                                            <TableCell>{player.position}</TableCell>
                                        </Hidden>
                                        <TableCell align="right">{player.points}</TableCell>
                                        <Hidden smDown>
                                            <TableCell align="right">{player.gamesPlayed}</TableCell>
                                        </Hidden>
                                        <Hidden smDown>
                                            <TableCell align="right">{player.ppg.toFixed(2)}</TableCell>
                                        </Hidden>
                                        <Hidden smDown>
                                            <TableCell align="right">{player.salary.toFixed(2)}</TableCell>
                                        </Hidden>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 50]}
                            component="div"
                            count={filteredData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage={isSmallScreen ? "#" : "Joueurs par page"}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                            ActionsComponent={TablePaginationActions}
                        />
                    </>)}
                </Paper>
            </Box>
        </Container>
    );
};

export default FreeAgents;