import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Table, CircularProgress, Link as LinkMUI, TableSortLabel, TableBody, TableCell, TableHead, TableRow, Paper, Box, Stack, Container, AppBar, Toolbar, Typography, IconButton, Button, MenuItem, Menu, Tooltip, Avatar } from '@mui/material';
import { fetchPoolTeams } from '../services/PoolTeamService';
import { Link } from 'react-router-dom';
import { BASE_URL as APP_URL } from '../constants';

function PoolTeamsTable() {
  const [data, setData] = useState([]);
  const { isLoading, isError } = useQuery('poolteams', fetchPoolTeams, {
    onSuccess: (poolTeams) => {
      const teamStats = poolTeams.map(team => {
        const stats = team.poolTeamPlayers.reduce((acc, poolTeamPlayer) => {
          const player = poolTeamPlayer.player;
          if (poolTeamPlayer.isInStartingLineup) {
            if (player.skaterStat) {
              acc.goals += player.skaterStat.goals;
              acc.assists += player.skaterStat.assists;
              acc.points += player.skaterStat.points;
              acc.gamesPlayed += player.skaterStat.gamesPlayed;
            }
            if (player.goalieStat) {
              acc.goals += player.goalieStat.goals;
              acc.assists += player.goalieStat.assists;
              acc.points += player.goalieStat.points;
              acc.gamesPlayed += player.goalieStat.gamesPlayed;
              acc.wins += player.goalieStat.wins;
              acc.shutouts += player.goalieStat.shutouts;
              acc.otl += player.goalieStat.overtimeLosses;
            }
          }
          return acc;
        }, { goals: 0, assists: 0, points: 0, gamesPlayed: 0, wins: 0, shutouts: 0, otl: 0 });

        return {
          teamId: team.id,
          teamName: team.team.frName,
          logoUrl: team.team.logoUrl,
          goals: stats.goals,
          assists: stats.assists,
          points: stats.points,
          gamesPlayed: stats.gamesPlayed,
          players: team.players,
        };
      });

      setData(teamStats);
    }
  });

  const [sortConfig, setSortConfig] = useState({ key: 'points', direction: 'desc' });

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedData = [...data].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? 1 : -1;
    }
    return 0;
  });

  return (
    <>
      <Container>
        <Box m={2}>
          <div style={{ maxWidth: 960, display: "block", marginLeft: 'auto', marginRight: 'auto' }}>
            <Paper>
              {isLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  <Typography variant="h6" style={{ padding: '16px' }}>
                    Classement
                  </Typography>
                  <Table size='small'>
                    <TableHead>
                      <TableRow>
                        <TableCell>Équipe</TableCell>
                        <TableCell align="right">
                          <TableSortLabel
                            active={sortConfig.key === 'points'}
                            direction={sortConfig.direction}
                            onClick={() => requestSort('points')}
                          >
                            Points
                          </TableSortLabel>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sortedData.map(poolTeam => (
                        <TableRow key={poolTeam.teamId}>
                          <TableCell>
                            <Stack direction="row" spacing={2} alignItems="center">
                              <Box sx={{ width: 40, height: 40, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <img src={`${APP_URL}${poolTeam.logoUrl}`} alt={poolTeam.teamName} style={{ maxWidth: '100%', maxHeight: '100%' }} />
                              </Box>

                              <LinkMUI component={Link} to={`/equipe/${poolTeam.teamId}`}>
                                {poolTeam.teamName}
                              </LinkMUI>
                            </Stack>
                          </TableCell>
                          <TableCell align="right">{poolTeam.points}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </>
              )}
            </Paper>
          </div>
        </Box>
      </Container >
    </>
  );
}

export default PoolTeamsTable;
